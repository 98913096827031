import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ToastifyAlert,
} from 'src/components/mui-components';

import { UseMutateFunction } from '@tanstack/react-query';
import { DialogProps } from '@mui/material';

interface IDeleteDialogProps extends Omit<DialogProps, 'onClose'> {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  itemId: number;
  name: string;
  deleteFunction: UseMutateFunction<any, unknown, number | undefined, unknown>;
}

export const DeleteDialog = ({
  setIsOpen,
  itemId,
  name,
  deleteFunction,
  open,
  ...rest
}: IDeleteDialogProps) => {
  const { t } = useTranslation('systemAdministration');

  // Actions
  const handleDelete = () => {
    deleteFunction(itemId, {
      onSuccess: () => {
        toast(
          <ToastifyAlert
            title={t('modals.DialogDeleteItemToastSuccessTitle', { ITEM_NAME: name })}
            color="success"
            data-automation-id="DeleteItemDialogToastSuccess"
          />,
          {
            autoClose: 4000,
            closeButton: false,
          },
        );
      },
      onError: (error) => {
        toast(
          <ToastifyAlert
            title={t('modals.DialogDeleteItemToastErrorTitle', { ITEM_NAME: name })}
            description={`${error}`}
            color="error"
            data-automation-id="DeleteItemDialogToastError"
          />,
          {
            closeButton: true,
          },
        );
      },
    });
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Dialog {...rest} open={open} onClose={handleCancel} data-automation-id="DeleteItemDialog">
      <DialogTitle>{t('modals.DialogDeleteItemHeader', { ITEM_NAME: name })}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
          {t('modals.DialogDeleteItemContentText')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCancel}
          data-automation-id="DeleteItemDialogButtonCancel"
        >
          {t('modals.DialogDeleteItemButtonCancelText')}
        </Button>
        <Button
          variant="contained"
          onClick={handleDelete}
          data-automation-id="DeleteItemDialogButtonDelete"
        >
          {t('modals.DialogDeleteItemButtonDeleteText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
