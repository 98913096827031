import { Close } from '@mui/icons-material';
import {
  CloseButtonProps,
  ToastContainer as ToastifyToastContainer,
  TypeOptions,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton } from 'src/components/mui-components/IconButton';

// Exclude 'info' as we do not have info status
type ExcludeInfo<T> = T extends 'info' ? never : T;
type TypeOmitInfo = ExcludeInfo<TypeOptions>;

const ToastCloseButton = ({
  closeToast,
  type,
}: Partial<CloseButtonProps> & { type?: TypeOmitInfo }) => (
  <IconButton
    onClick={closeToast}
    title="Close"
    style={{
      position: 'absolute',
      top: '50%',
      right: 16,
      transform: 'translateY(-50%)',
    }}
    color={type}
  >
    <Close fontSize="small" />
  </IconButton>
);

export const ToastContainer = () => (
  <ToastifyToastContainer
    position="bottom-center"
    autoClose={false}
    hideProgressBar
    newestOnTop
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    closeButton={<ToastCloseButton />}
    icon={false}
    style={{ zIndex: 9999 }}
    bodyStyle={{ padding: 0 }}
    toastStyle={{ padding: 0 }}
  />
);
