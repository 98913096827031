import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ToastifyAlert,
} from 'src/components/mui-components';
import { z } from 'zod';

import { zodResolver } from '@hookform/resolvers/zod';

import { usePostCompetenceGroups, useUpdateCompetenceGroups } from 'src/apis/competenceGroupAPI';
import { DialogProps } from '../types';

export const NewCompetenceCategoryDialog = ({
  setIsOpen,
  setRowInfo,
  rowInfo,
  isEditing,
  setIsEditing,
  ...rest
}: DialogProps) => {
  const { t } = useTranslation('systemAdministrationEmployeesCompetences');

  // Form handling
  const formSchema = z.object({
    categoryName: z.string().min(1),
  });

  const form = useForm({
    defaultValues: {
      categoryName: rowInfo?.name || '',
    },
    resolver: zodResolver(formSchema),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = form;

  // Data
  const { mutate: postCompetenceGroup } = usePostCompetenceGroups();
  const { mutate: updateCompetenceGroup } = useUpdateCompetenceGroups();

  // Actions
  const cleanUp = () => {
    setIsOpen(false);
    reset();
    if (setRowInfo) setRowInfo({});
    if (setIsEditing) setIsEditing(false);
  };

  const handleCreate = () => {
    postCompetenceGroup(
      {
        name: form.getValues('categoryName'),
      },
      {
        onSuccess: () => {
          toast(
            <ToastifyAlert
              title={t('modals.CategoryCreatedToastTitle', {
                CATEGORY_NAME: form.getValues('categoryName'),
              })}
              description={t('modals.CategoryCreatedToastDescription')}
              color="success"
              data-automation-id="CreateCompetenceCategoryDialogToastSuccess"
            />,
            { autoClose: 4000, closeButton: false },
          );
          cleanUp();
        },
        onError: (error) => {
          toast(
            <ToastifyAlert
              title={`${form.getValues('categoryName')} was not created`}
              description={`${error}`}
              color="error"
              data-automation-id="CreateCompetenceCategoryDialogToastError"
            />,
            { closeButton: true },
          );
        },
      },
    );
  };

  const handleEdit = () => {
    updateCompetenceGroup(
      {
        id: rowInfo?.apiId,
        name: form.getValues('categoryName'),
      },
      {
        onSuccess: () => {
          toast(
            <ToastifyAlert
              title={t('modals.CategoryEditedToastTitle', {
                CATEGORY_NAME: form.getValues('categoryName'),
              })}
              description={t('modals.CategoryEditedToastDescription')}
              color="success"
              data-automation-id="EditCompetenceCategoryDialogToastSuccess"
            />,
            { autoClose: 4000, closeButton: false },
          );
          cleanUp();
        },
        onError: (error) => {
          toast(
            <ToastifyAlert
              title={t('modals.CategoryEditToastTitleError', {
                CATEGORY_NAME: form.getValues('categoryName'),
              })}
              description={`${error}`}
              color="error"
              data-automation-id="EditCompetenceCategoryDialogToastError"
            />,
            { closeButton: true },
          );
        },
      },
    );
  };

  const handleCancel = () => {
    cleanUp();
  };

  return (
    <Dialog
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      data-automation-id={`${isEditing ? 'Edit' : 'Create'}CompetenceCategoryDialog`}
      {...rest}
    >
      <form onSubmit={handleSubmit(isEditing ? handleEdit : handleCreate)}>
        <DialogTitle>
          {isEditing ? t('modals.CategoryEditHeader') : t('modals.CategoryCreateHeader')}
        </DialogTitle>
        <DialogContent>
          {/* Add the box to make room for the floating label */}
          <Box sx={{ pt: 1 }}>
            <Controller
              name="categoryName"
              control={control}
              render={({ field }) => (
                <TextField
                  data-automation-id={`${isEditing ? 'Edit' : 'Create'}CompetenceCategoryInputName`}
                  label={t('modals.CategoryCreateInputNameLabel')}
                  variant="outlined"
                  ariaLabel={t('modals.CategoryCreateInputNameLabel')}
                  required
                  fullWidth
                  {...field}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCancel}
            data-automation-id={`${
              isEditing ? 'Edit' : 'Create'
            }CompetenceCategoryDialogButtonCancel`}
          >
            {t('modals.CreateButtonCancelText')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!isDirty || !isValid}
            data-automation-id={`${
              isEditing ? 'Edit' : 'Create'
            }CompetenceCategoryDialogButtonSave`}
          >
            {t('modals.CreateButtonSaveText')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
