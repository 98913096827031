import {
  Box,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
  DialogContentText as MuiDialogContentText,
  DialogContentTextProps as MuiDialogContentTextProps,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  Stack,
} from '@mui/material';
import { IconButton } from 'src/components/mui-components';
import { Close } from '@mui/icons-material';

interface IDialogTitle extends MuiDialogTitleProps {
  childComponent?: React.ReactNode;
  closeText?: string;
  closeModal?: () => void;
  children?: React.ReactNode;
  [x: string]: any;
}

export const Dialog = ({ ...rest }: MuiDialogProps) => <MuiDialog {...rest} />;
export const DialogActions = ({ ...rest }: MuiDialogActionsProps) => <MuiDialogActions {...rest} />;
export const DialogContent = ({ ...rest }: MuiDialogContentProps) => (
  // we need padding in the top to make room for a label on text fields
  <MuiDialogContent {...rest} sx={{ pt: 1, ...rest.sx }} />
);
export const DialogContentText = ({ ...rest }: MuiDialogContentTextProps) => (
  <MuiDialogContentText {...rest} />
);

export const DialogTitle = ({
  headingComponent,
  closeText,
  closeModal,
  children,
  ...rest
}: IDialogTitle) => (
  <MuiDialogTitle {...rest}>
    <Box display="flex" alignItems="center">
      <Box flexGrow={1}>{children}</Box>
      <Stack direction="row" alignItems="center">
        {headingComponent}
        {closeText && (
          <IconButton
            aria-label="close"
            onClick={closeModal}
            title={closeText}
            data-automation-id="ModalCloseButton"
          >
            <Close />
          </IconButton>
        )}
      </Stack>
    </Box>
  </MuiDialogTitle>
);
