import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { IConvertedRow, COMPETENCES_URL_PATH, GET_COMPETENCES_KEY } from '../types';

export const POST_COMPETENCES_MSW_STATUS_KEY = 'postCompetence';

export const POST_COMPETENCES_KEY = 'POST_COMPETENCES';

const postCompetence = ({ name, category, isActive }: IConvertedRow) =>
  postFetch({
    path: COMPETENCES_URL_PATH,
    key: POST_COMPETENCES_MSW_STATUS_KEY,
    body: JSON.stringify({
      Name: name,
      IsActive: isActive,
      GroupId: category,
    }),
  });

export const usePostCompetence = () => {
  const qc = useQueryClient();

  return useMutation(postCompetence, {
    onSuccess: () => {
      qc.invalidateQueries([GET_COMPETENCES_KEY]);
    },
  });
};
