import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { forwardRef } from 'react';
import { Tooltip } from 'src/components/mui-components';

type IconButtonRef = HTMLButtonElement & {
  title: string;
};

interface IconButtonProps extends MuiIconButtonProps {
  href?: string;
  target?: string;
}

export const IconButton = forwardRef<IconButtonRef, IconButtonProps>(({ title, ...rest }, ref) => (
  <Tooltip title={rest.disabled ? undefined : title} disableInteractive>
    {/* Reason for div here: https://mui.com/material-ui/react-tooltip/#disabled-elements */}
    <div>
      <MuiIconButton ref={ref} aria-label={title} {...rest} />
    </div>
  </Tooltip>
));
