import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  TextField,
} from 'src/components/mui-components';
import { FormControlLabel } from 'src/components/mui-components/Utils';
import { PERIOD } from '../../constants';
import { ISavedViewForm, TFormDialogMode, TextDialogMode } from '../../types/resourcePlanner';

interface IViewFormDialog {
  showFormDialog: TFormDialogMode;
  setShowFormDialog: (showFormDialog: TFormDialogMode | '') => void;
  onSubmit: (data: ISavedViewForm) => void;
  form: UseFormReturn<ISavedViewForm, any>;
}

export const ViewFormDialog = ({
  showFormDialog,
  setShowFormDialog,
  onSubmit,
  form,
}: IViewFormDialog) => {
  const { t } = useTranslation('savedView');
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = form;

  const dialogTitle = (view: TFormDialogMode) => {
    switch (view) {
      case 'save':
        return t('SaveViewModalTitle');
      case 'edit':
        return t('UpdateViewModalTitle');
      case 'duplicate':
        return t('DuplicateViewModalTitle');
      default:
        return '';
    }
  };

  return (
    <Dialog
      open={!!showFormDialog}
      onClose={() => setShowFormDialog('')}
      aria-labelledby="dialog-form"
      aria-describedby="dialog-form-description"
    >
      <DialogTitle id="dialog-form">{dialogTitle(showFormDialog)}</DialogTitle>
      <DialogContent sx={{ width: 444 }}>
        <Stack gap={2} sx={{ marginTop: 2 }}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                data-automation-id="SavedViewTitleInput"
                label="Title"
                variant="outlined"
                ariaLabel="view-title"
                required
                error={!!errors.title?.message?.toString()}
                helperText={errors.title?.message?.toString()}
                {...field}
              />
            )}
          />
          <Controller
            name="period"
            control={control}
            render={({ field }) => (
              <Select
                label="Period"
                labelId="period-label"
                required
                error={!!errors.period?.message?.toString()}
                helperText={errors.period?.message?.toString()}
                data-automation-id="SavedViewPeriodSelect"
                {...field}
              >
                {PERIOD.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <FormControlLabel
            control={
              <Controller
                name="isDefault"
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={t('DialogIsDefaultText')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setShowFormDialog('')}>
          {t('DialogCancelText')}
        </Button>
        <Button
          variant="contained"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(onSubmit)}
          autoFocus
        >
          {t('DialogSaveText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface IViewTextDialog {
  showTextDialog: TextDialogMode;
  setShowTextDialog: (showTextDialog: TextDialogMode | '') => void;
  onCancel: () => void;
  onDelete: () => void;
  onChangesUpdate: () => void;
}

export const ViewTextDialog = ({
  showTextDialog,
  setShowTextDialog,
  onCancel,
  onDelete,
  onChangesUpdate,
}: IViewTextDialog) => {
  const { t } = useTranslation('savedView');
  const cancelOnClick = () => {
    setShowTextDialog('');
    onCancel();
  };
  return (
    <Dialog
      open={!!showTextDialog}
      onClose={cancelOnClick}
      aria-labelledby="dialog-text"
      aria-describedby="dialog-text-description"
    >
      <DialogTitle id="dialog-text">
        {showTextDialog === 'delete' ? t('DeleteViewModalTitle') : t('ChangesViewModalTitle')}
      </DialogTitle>
      <DialogContent sx={{ width: 444 }}>
        {showTextDialog === 'delete' ? t('DeleteViewModalDesc') : t('ChangesViewModalDesc')}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={cancelOnClick}>
          {showTextDialog === 'delete' ? t('DialogCancelText') : t('DialogNoText')}
        </Button>
        <Button
          variant="contained"
          onClick={showTextDialog === 'delete' ? onDelete : onChangesUpdate}
          autoFocus
        >
          {showTextDialog === 'delete' ? t('DialogOkText') : t('DialogYesText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
